import React from 'react';
import { HOME_PAGE } from '../../constants/route.const';
import classes from './styles.module.scss';
import LogoWithTitle from 'public/assets/logo/sikka.svg';
import ProLogoWithTitle from 'public/assets/logo/SP.svg';
import Link from 'next/link';

export default function Header({ rightContent, isPro }) {
  return (
    <header className={classes.header}>
      <div className={classes.left}>
        <Link href={HOME_PAGE} className={classes.logoLink}>
          {isPro ? <ProLogoWithTitle /> : <LogoWithTitle />}
        </Link>
      </div>
      <div className={classes.right}>{rightContent && rightContent}</div>
    </header>
  );
}
// export default Header;

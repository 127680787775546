import React from 'react';
import classes from './styles.module.scss';
import Link from 'next/link';
import { Stack, Icon } from '@chakra-ui/react';
import SikkaLogo from '/public/assets/logo/logo-with-title.svg';
import ProLogoWithTitle from 'public/assets/logo/SP.svg';
import MobileNavbar from './MobileNavbar';
import useMediaQuery from 'hooks/useMediaQuery';
import { MdLanguage } from 'react-icons/md';
import SKButton from 'components/UI/SKButton';
import { useRouter } from 'next/router';
import {
  FAQS_PAGE,
  VIDEO_PAGE,
  HOW_IT_WORKS_PAGE,
  FEATURE_PAGE,
  PROFILE_PAGE,
  BLOG_ROUTE
} from 'constants/route.const';
import { StorageService } from 'service/Storage';

function DesktopNavbar({ rightContent, isProType }) {
  const router = useRouter();

  const isNotLoggedIn = StorageService.getUserId() ? false : true;

  const navData = [
    {
      name: 'How it works',
      link: HOW_IT_WORKS_PAGE
    },
    {
      name: 'Blogs',
      link: BLOG_ROUTE
    },
    {
      name: 'Features',
      link: FEATURE_PAGE
    },
    {
      name: 'FAQs',
      link: FAQS_PAGE
    },
    {
      name: 'Videos',
      link: VIDEO_PAGE
    }
  ];

  // const LangIcon = () => <Icon as={MdLanguage} boxSize={5} color="#FFC165" />;

  return (
    <div className={classes.desktopNav}>
      <div className={classes.logo}>
        <Link href={'/'}>
          {isProType ? <ProLogoWithTitle /> : <SikkaLogo />}
        </Link>
      </div>
      <div className={classes.rightNav}>
        <ul className={classes.flexLink}>
          {navData.map(val => (
            <li key={val.name}>
              <Link href={val.link}>{val.name}</Link>
            </li>
          ))}
        </ul>
        {rightContent ? (
          rightContent
        ) : isNotLoggedIn ? null : (
          <div className={classes.flexBtn}>
            <SKButton
              onClick={() => {
                router.push(PROFILE_PAGE);
              }}
            >
              Check Wallet
            </SKButton>
          </div>
        )}
      </div>
    </div>
  );
}

function Navbar(props) {
  const isTablet = useMediaQuery('(max-width: 1000px)');

  return (
    <div className={classes.navbarWrap}>
      {isTablet ? <MobileNavbar {...props} /> : <DesktopNavbar {...props} />}
    </div>
  );
}

export default Navbar;

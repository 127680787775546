import React from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import classes from './styles.module.scss';
import Navbar from 'components/Navbar';

function Layout({
  headerProps,
  isPro:isProType,
  footerProps,
  layoutProps,
  children,
  hideFooter,
}) {
  return (
    <div className={classes.Layout}>
      <div className={classes.Header}>
        <Navbar {...headerProps} isProType={isProType} />
        {/* <Header {...headerProps} isPro={isPro} /> */}
      </div>
      <main>{children}</main>
      {hideFooter ? null : (
        <div className={classes.Footer}>
          <Footer {...footerProps} />
        </div>
      )}
    </div>
  );
}
export default Layout;

import React from 'react';
import classes from './styles.module.scss';
import Link from 'next/link';
import { Select } from '@chakra-ui/react';
import SikkaLogoWithTitle from '/public/assets/logo/logo-with-title.svg';
import SikkaLogo from '/public/assets/logo/logo.svg';
import SikkaPro from 'public/assets/logo/pro-logo.svg';
import ProLogoWithTitle from 'public/assets/logo/SP.svg';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Icon,
  Stack
} from '@chakra-ui/react';
import { HiOutlineMenu } from 'react-icons/hi';
import { MdLanguage } from 'react-icons/md';
import useMediaQuery from 'hooks/useMediaQuery';
import SKButton from 'components/UI/SKButton';
import { useRouter } from 'next/router';
import {
  FAQS_PAGE,
  VIDEO_PAGE,
  HOW_IT_WORKS_PAGE,
  FEATURE_PAGE,
  PROFILE_PAGE,
  BLOG_ROUTE
} from 'constants/route.const';
import { StorageService } from 'service/Storage';

function MobileNavbar({ rightContent, isProType }) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const router = useRouter();

  const isNotLoggedIn = StorageService.getUserId() ? false : true;

  const navData = [
    {
      name: 'How it works',
      link: HOW_IT_WORKS_PAGE
    },
    {
      name: 'Blogs',
      link: BLOG_ROUTE
    },
    {
      name: 'Features',
      link: FEATURE_PAGE
    },
    {
      name: 'FAQs',
      link: FAQS_PAGE
    },
    {
      name: 'Videos',
      link: VIDEO_PAGE
    }
  ];

  const MenuBtn = () => <Icon as={HiOutlineMenu} boxSize={6} />;

  return (
    <div className={classes.mobNav}>
      <div className={classes.menuSide}>
        <Menu>
          <MenuButton
            className={classes.navBtn}
            as={IconButton}
            aria-label="Options"
            icon={<MenuBtn />}
            variant="outline"
          />
          <MenuList>
            {navData.map(val => (
              <Link key={val.name} href={val.link}>
                <MenuItem>{val.name}</MenuItem>
              </Link>
            ))}
          </MenuList>
        </Menu>
        <div className={classes.logo}>
          <Link href={'/'}>
            {isMobile ? (
              isProType ? (
                <SikkaPro className={classes.showMobile} />
              ) : (
                <SikkaLogo className={classes.showMobile} />
              )
            ) : isProType ? (
              <ProLogoWithTitle className={classes.showDesktop} />
            ) : (
              <SikkaLogoWithTitle className={classes.showDesktop} />
            )}
          </Link>
        </div>
      </div>
      {rightContent ? (
        rightContent
      ) : isNotLoggedIn ? null : (
        <div className={classes.btnSide}>
          <SKButton
            onClick={() => {
              router.push(PROFILE_PAGE);
            }}
          >
            Check Wallet
          </SKButton>
        </div>
      )}
    </div>
  );
}

export default MobileNavbar;

import React from 'react';
import Link from 'next/link';
import classes from './styles.module.scss';
import {
  PRIVACY_POLICY_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  FAQS_PAGE,
  SIKKA_QUIZ_TERMS_AND_CONDITIONS_PAGE
} from '../../constants/route.const';
import Facebook from 'public/assets/icons/facebook.svg';
import Instagram from 'public/assets/icons/instagram.svg';
import Youtube from 'public/assets/icons/youtube.svg';
import Telegram from 'public/assets/icons/telegram2.svg';
import Twitter from 'public/assets/icons/twitter.svg';
import { Heading, Text } from '@chakra-ui/react';

const socialData = [
  {
    title: 'Facebook',
    icon: Facebook,
    link: 'https://www.facebook.com/profile.php?id=100074959262484'
  },
  {
    title: 'Instagram',
    icon: Instagram,
    link: 'https://www.instagram.com/sikka_app/'
  },
  {
    title: 'Youtube',
    icon: Youtube,
    link: 'https://www.youtube.com/channel/UCVw9fGdKbRI9NKvjgGfeGsA'
  },
  { title: 'Telegram', icon: Telegram, link: 'https://t.me/+vaT2f1bG-HYyOWI1' },
  { title: 'Twitter', icon: Twitter, link: 'https://twitter.com/sikkaapp' }
];

const footerLinks = [
  {
    name: 'Privacy Policy',
    link: PRIVACY_POLICY_PAGE
  },
  {
    name: 'FAQ',
    link: FAQS_PAGE
  },
  {
    name: 'Terms & Conditions',
    link: TERMS_AND_CONDITIONS_PAGE
  }
];

function Footer(props) {
  return (
    <footer className={classes.footer}>
      <Text>Follow us for regular updates</Text>
      <div className={classes.iconContainer}>
        {socialData.map(val => (
          <a
            target={'_blank'}
            rel="nofollow noreferrer noopener"
            href={val.link}
            key={val.title}
          >
            <val.icon />
          </a>
        ))}
      </div>
      <div className={classes.footerLinkContainer}>
        {footerLinks.map(val => (
          <Link href={val.link} key={val.name}>
            {val.name}
          </Link>
        ))}
      </div>
    </footer>
  );
}
export default Footer;

export const HOME_PAGE = '/';
export const VERIFY_PAGE = '/verify';
export const VERIFY_PAGE_PRO = '/verify?type=pro';
export const PROFILE_PAGE = '/profile';
export const PRIVACY_POLICY_PAGE = '/privacy-policy';
export const TERMS_AND_CONDITIONS_PAGE = '/terms-conditions';
// export const FAQS_PAGE = '/faqs';
export const DOWNLOAD_PAGE = '/download';
export const REFERRAL_PAGE = '/referral';
export const OFFER_PAGE = '/mahotsava';
export const MAHOTSAVA_TERMS_AND_CONDITIONS_PAGE =
  '/mahotsava-terms-conditions';
export const MAHOTSAVA_HOW_TO_PLAY_PAGE = '/mahotsava-how-to-play';
export const SIKKA_QUIZ_TERMS_AND_CONDITIONS_PAGE =
  '/sikka-quiz-terms-conditions';
export const TOURNAMENT_PAGE = '/tournament/:tmID';
export const TOURNAMENT_GAME_PLAY = '/tournament/:tmID/:ctID/:rdID';
export const TOURNAMENT_LEADERBOARD = '/tournament/:tmID/leaderboard';
export const GET_TM_PAGE = tmID => `/tournament/${tmID}`;
export const GET_QUIZ_PAGE = ({ tmID, ctID, rdID }) =>
  `/tournament/${tmID}/${ctID}/${rdID}`;
export const GET_TM_LEADERBOARD = tmID => `/tournament/${tmID}/leaderboard`;

//new
export const FAQS_PAGE = '/faqs';
export const VIDEO_PAGE = '/how-to-make-money-online-videos';
export const HOW_IT_WORKS_PAGE = '/how-it-works';
export const FEATURE_PAGE = '/features-online-money-earning-app';
export const BLOG_ROUTE = '/blogs';
export const CONTEST_PAGE = '/contest';
export const GET_CONTEST_DETAILS_PAGE = id => `${CONTEST_PAGE}/${id}`;
